import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/mainPage";
import SuccessPage from "./pages/SuccessPage"; // Ensure you have created this component
import TermsAndConditions from "./pages/toc";
import HomePage from "./pages/homePage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/generate" element={<MainPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/toc" element={<TermsAndConditions />} />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

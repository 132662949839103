import React from "react";
import "../App.css";

function TermsAndConditions() {
  return (
    <div>
      <a className="back-button" href="/">
        Back
      </a>
      <div className="toc-container">
        <h2>Terms and Conditions for RecAIpe</h2>
        <div className="scrollable-content">
          <p>
            <strong>Legal Disclaimer for RecAIpe</strong>
          </p>

          <h3>1. Acceptance of Terms</h3>
          <p>
            By using RecAIpe ("the Service"), you agree to be bound by these
            Terms and Conditions ("Terms"). Please read these Terms carefully.
            If you do not agree with any part of these Terms, you must not use
            the Service.
          </p>

          <h3>2. Description of Service</h3>
          <p>
            The Service includes the generation of eCookbooks using artificial
            intelligence (AI) technology. The AI algorithms are provided by
            third-party language models, and the Service acts as an intermediary
            platform facilitating eCookbook creation.
          </p>

          <h3>3. No Liability for Generated Content</h3>
          <p>
            RecAIpe does not guarantee the accuracy, reliability, or
            appropriateness of any content generated through the Service. The
            content is produced automatically by AI, based on input and
            algorithms provided by third-party language models. As such, RecAIpe
            does not review, endorse, or take responsibility for any content
            generated, including but not limited to inaccuracies, errors, or
            potential plagiarism.
          </p>

          <h3>4. Copyright and Content Ownership</h3>
          <p>
            The user acknowledges that the content generated by the Service may
            inadvertently infringe on existing copyrights or contain elements of
            copyrighted material. RecAIpe does not claim ownership of the
            content generated by users through the Service but does not assume
            liability for copyright infringements or plagiarism arising from the
            generated eCookbook content.
          </p>

          <h3>5. User Responsibilities</h3>
          <p>
            Users of the Service are solely responsible for the eCookbook
            content they generate, including ensuring that such content does not
            violate copyright or other intellectual property rights. Users
            should exercise caution and perform due diligence to ensure that the
            content generated is used lawfully and ethically.
          </p>

          <h3>6. Indemnification</h3>
          <p>
            Users agree to indemnify and hold harmless RecAIpe, its affiliates,
            officers, agents, and employees from any claim or demand, including
            reasonable attorneys' fees, made by any third party due to or
            arising out of the eCookbook content they generate, their use of the
            Service, or their violation of these Terms.
          </p>

          <h3>7. Infrastructure Errors</h3>
          <p>
            RecAIpe makes best efforts to ensure the proper functioning of its
            infrastructure. However, in the event of infrastructure errors that
            may cause orders not to be fulfilled, RecAIpe will use its best
            efforts to promptly identify and rectify the issue if it occurs.
            RecAIpe shall not be liable for any losses or damages resulting from
            such infrastructure errors.
          </p>

          <h3>8. Limitation of Liability</h3>
          <p>
            In no event shall RecAIpe be liable for any direct, indirect,
            incidental, special, consequential, or exemplary damages, including
            but not limited to damages for loss of profits, goodwill, use, data,
            or other intangible losses (even if RecAIpe has been advised of the
            possibility of such damages), resulting from the use or the
            inability to use the Service.
          </p>

          <h3>9. Modification of Terms</h3>
          <p>
            RecAIpe reserves the right to modify these Terms at any time without
            prior notice. Your continued use of the Service following any such
            modification constitutes your acceptance of the new Terms.
          </p>

          <h3>10. Governing Law</h3>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of United Kingdom, without giving effect to any principles
            of conflicts of law.
          </p>

          <h3>11. Acknowledgement</h3>
          <p>
            By using the Service, you acknowledge that you have read,
            understood, and agree to be bound by these Terms.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;

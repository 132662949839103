import React, { useMemo } from "react";
import "../App.css";

function PDFViewer({ pdfBase64 }) {
  const isIOS = useMemo(() => {
    const userAgent = window.navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent);
  }, []);

  const pdfDataUrl = useMemo(() => {
    if (isIOS) {
      return null; // Return null if it's an iOS device
    }

    try {
      // Ensure we're only dealing with the base64 portion
      const base64EncodedPDF = pdfBase64.startsWith(
        "data:application/pdf;base64,"
      )
        ? pdfBase64.split("data:application/pdf;base64,")[1]
        : pdfBase64;

      const binaryString = window.atob(base64EncodedPDF);
      const len = binaryString.length;
      const binaryArray = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
      }

      // Create and return the blob URL
      const pdfBlob = new Blob([binaryArray], { type: "application/pdf" });
      return URL.createObjectURL(pdfBlob);
    } catch (error) {
      console.error("Error decoding base64 PDF:", error);
      return ""; // Return an empty string or handle the error as appropriate
    }
  }, [pdfBase64, isIOS]);

  if (isIOS) {
    return (
      <div className="pdf-container">
        Sorry Apple does not allow PDF's to load on iOS, please use another
        device. Full pdf generation is still available for download when
        purchased.
      </div>
    );
  }

  return (
    <div className="pdf-container">
      <iframe
        src={pdfDataUrl}
        title="PDF Viewer"
        width="100%"
        height="100%"
        style={{ border: "none", overflow: "scroll" }}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default PDFViewer;

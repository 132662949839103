import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import PDFViewer from "../components/PDFViewer";

const serverUrl = process.env.REACT_APP_SERVER_URL;

function SuccessPage() {
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const [pdfLinks, setPdfLinks] = useState({
    viewableLink: "",
    downloadLink: "",
  });

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get("session_id");
    if (sessionId && !isVerified) {
      verifySessionAndGenerateCookbook(sessionId);
      setIsVerified(true); // Mark as verified to prevent future executions
    }
  }, [location, isVerified]);

  const verifySessionAndGenerateCookbook = async (sessionId) => {
    try {
      const response = await fetch(`${serverUrl}/api/verify-payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ session_id: sessionId }),
      });

      if (!response.ok) throw new Error("Payment verification failed");

      const pdfData = await response.json();
      setPdfLinks({
        viewableLink: pdfData.viewableUrl,
        downloadLink: pdfData.downloadUrl,
      });
      console.log(
        "Cookbook generated and available at:",
        pdfData.viewableUrl,
        pdfData.downloadUrl
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const LoadingDots = () => (
    <div className="loading-dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  return (
    <div className="success-container">
      <h2 className="success-thankyou">Thank you for your purchase!</h2>
      <p className="success-info">
        Your cookbook is being generated, a download button will appear below
        when complete, this may take up to 7 minutes due to the file size.
      </p>
      <div className="download-container">
        {pdfLinks.downloadLink ? (
          <a
            className="download-button"
            href={pdfLinks.downloadLink}
            download="Cookbook.pdf"
          >
            Download Cookbook
          </a>
        ) : (
          <LoadingDots />
        )}
      </div>
      <p className="success-error-advice">
        If you encounter an error please contact support at{" "}
        <a className="success-email" href="mailto:recaipe.io@gmail.com">
          recaipe.io@gmail.com
        </a>
      </p>
    </div>
  );
}

export default SuccessPage;

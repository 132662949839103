import React, { useEffect, useState } from "react";
import "../css/homepage.css"; // Ensure the path matches your project structure
import Footer from "../components/footer";
import logo from "./../imgs/logo1.png"; // Adjust the path as necessary

function HomePage() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div>
      <div className={`main-container ${isLoaded ? "fade-in" : ""}`}>
        <div className="new-container">
          <div className="welcome-section">
            <img className="home-logo" src={logo} alt="RecAIpe Logo" />

            <p className="home-title">
              RecAIpe | Generate a ready to publish cookbook in minutes{" "}
            </p>
            <p className="intro-text">
              Dive into the culinary world with our eCookbook generator, each
              tailored to a style of your choosing. Our books are brimming with
              high-quality, identical to life AI-generated images that bring
              every recipe to reality.
            </p>
            <a href="/generate" className="start-button">
              Generate cookbook
            </a>
          </div>
          <div className="featured-recipes">
            <div className="scroll-container">
              <a
                href="https://cookpository.onrender.com/italian_delights_cookbook.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Italian Delights: Authentic recipes straight from Italy
                </h3>
                <p className="date-created">Created 5 days ago</p>
                <p className="prompt">Cookbook full of italian recipes</p>
              </a>
              <a
                href="https://cookpository.onrender.com/asian.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Asian Fusion: A blend of Asian flavors with a modern twist
                </h3>
                <p className="date-created">Created 3 days ago</p>
                <p className="prompt">Asian food in a modern style</p>
              </a>
              <a
                href="https://cookpository.onrender.com/mediterranean_medley__a_cookbook_adventure.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Mediterranean Medley: A journey through Mediterranean cuisine
                </h3>
                <p className="date-created">Created 1 week ago</p>
                <p className="prompt">Fresh Mediterranean cookbook</p>
              </a>
              <a
                href="https://cookpository.onrender.com/vegan_vibes_cookbook.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Vegan Vibes: Delicious plant-based recipes for every occasion
                </h3>
                <p className="date-created">Created 2 days ago</p>
                <p className="prompt">Vegan cookbook</p>
              </a>
            </div>
            <div className="scroll-container">
              <a
                href="https://cookpository.onrender.com/italian_delights_cookbook.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Italian Delights: Authentic recipes straight from Italy
                </h3>
                <p className="date-created">Created 5 days ago</p>
                <p className="prompt">Cookbook full of italian recipes</p>
              </a>
              <a
                href="https://cookpository.onrender.com/asian.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Asian Fusion: A blend of Asian flavors with a modern twist
                </h3>
                <p className="date-created">Created 3 days ago</p>
                <p className="prompt">Asian food in a modern style</p>
              </a>
              <a
                href="https://cookpository.onrender.com/mediterranean_medley__a_cookbook_adventure.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Mediterranean Medley: A journey through Mediterranean cuisine
                </h3>
                <p className="date-created">Created 1 week ago</p>
                <p className="prompt">Fresh Mediterranean cookbook</p>
              </a>
              <a
                href="https://cookpository.onrender.com/vegan_vibes_cookbook.pdf"
                className="recipe-link"
              >
                <h3 className="book-title">
                  Vegan Vibes: Delicious plant-based recipes for every occasion
                </h3>
                <p className="date-created">Created 2 days ago</p>
                <p className="prompt">Vegan cookbook</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;

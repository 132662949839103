import React, { useState, useEffect } from "react";
import PDFViewer from "../components/PDFViewer";
import "../App.css";
import Footer from "../components/footer.js";

function MainPage() {
  const [requestText, setRequestText] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    if (!serverUrl) {
      console.error(
        "Server URL not found. Please check your environment variables."
      );
      setMessage("Error: Server URL not found.");
    }
  }, []);

  const handleChange = (e) => {
    setRequestText(e.target.value);
    setMessage("");
  };

  // Function to detect iOS devices
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  };

  const initiatePayment = async () => {
    setIsLoading(true);
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const response = await fetch(`${serverUrl}/api/create-checkout-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userPrompt: requestText }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const session = await response.json();
      window.location.href = session.url;
    } catch (error) {
      console.error("Error initiating payment:", error);
      setMessage("Error initiating payment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (generateFullBook) => {
    if (requestText.trim() === "") {
      setMessage("Please fill in a request");
      return;
    }

    if (!generateFullBook && isLoading) {
      return;
    }

    setIsLoading(true);

    if (!generateFullBook) {
      // This block is only for generating the preview, not the full book
      if (isIOS()) {
        setMessage(
          "Sorry, Apple does not allow PDFs to load on iOS. Please use another device. Full cookbook generation will still operate and be available download when purchased on iOS devices."
        );
        setIsLoading(false); // Stop the loading indicator
        return; // Stop execution if it's an iOS device
      }

      setMessage("Loading your cookbook preview...");

      try {
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const response = await fetch(`${serverUrl}/api/getPreview`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userPrompt: requestText }),
        });

        if (!response.ok) {
          throw new Error("Failed to get response from OpenAI API");
        }

        const cookbook = await response.json();
        const pdfResponse = await fetch(`${serverUrl}/api/generatePDFBlob`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(cookbook),
        });

        if (!pdfResponse.ok) {
          throw new Error("Failed to generate PDF Blob");
        }

        const { pdfData } = await pdfResponse.json();
        setPdfBlob(pdfData);
        setMessage("Preview generated successfully!");
      } catch (error) {
        console.error("Error:", error);
        setMessage(error.toString());
      } finally {
        setIsLoading(false);
      }
    } else {
      // This block is for initiating the full book generation/payment
      initiatePayment();
    }
  };

  const LoadingDots = () => (
    <div className="loading-dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  return (
    <div className="ai-container">
      <div className="section">
        <div className="request-section">
          <div className="intro-text">
            <p className="page-title">Create your own masterpiece</p>
            <p className="description">
              Embark on a culinary adventure with our AI Cookbook Creator.
              Simply write your cookbook theme as specifcally as you would like,
              for example 'a cookbook for bodybuilders that only eat cabbage'.
              Curious about new flavors? Share your interests, and our AI will
              tailor a custom cookbook just for you. Click to preview or for
              £3.50 fully generate your personalized culinary guide filled with
              identical to life images. Begin crafting your unique culinary
              journey now!
            </p>
          </div>
          <h2>{message ? <p className="error-message">{message}</p> : ""}</h2>
          <input
            type="text"
            placeholder="Type your cookbook theme here"
            value={requestText}
            onChange={handleChange}
          />
          <button onClick={() => handleSubmit(false)} disabled={isLoading}>
            Preview Cookbook
          </button>
          <button onClick={() => handleSubmit(true)} disabled={isLoading}>
            Generate Full Book
          </button>
          <ul>
            <li>
              Cookbook length will be 40 - 60 pages. It will consist of 5
              chapters, each having 5 recipes, as outlined in the previewed
              Table of Contents.
            </li>
            <li>
              Final cookbook may differ slightly than preview, as it will be
              regenerated.
            </li>
          </ul>
        </div>
        <div className="response-section">
          {pdfBlob ? (
            <PDFViewer pdfBase64={pdfBlob} /> // Ensure you're passing the Base64 string correctly
          ) : isLoading ? (
            <div className="api-response-window">
              <LoadingDots />
            </div>
          ) : (
            <div className="api-response-window">
              <h2>A preview of your cookbook will show here.</h2>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MainPage;

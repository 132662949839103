import React from "react";
import "../App.css";

function Footer() {
  return (
    <footer className="app-footer">
      <p>
        By using this website, you are agreeing to the{" "}
        <a href="/toc">Terms and Conditions</a> of the website.
      </p>
    </footer>
  );
}

export default Footer;
